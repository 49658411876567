import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useLayoutEffect, useState} from "react";
import  {isLoggedIn} from "../utils/auth.service";
import {graphql, navigate} from "gatsby";
import authHeader from "../utils/auth-header";
import LoadingSpinner from "../components/loading-spinner";
import VehiclesList from "../components/vehicles/list";
import AddVehicle from "../components/vehicles/add";
import toast, {Toaster} from "react-hot-toast";
import Popup from "../components/popup";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Vehicles = () => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [makeAndModel, setMakeAndModel] = useState('');
    const [regNumber, setRegNumber] = useState('');
    const [categoryId, setCategoryId] = useState(0);
    const [vehicleCategories, setVehicleCategories] = useState([]);

    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });


    useEffect(() => {

        loadVehicles();


    }, [])


    /*
    @todo to one function
     */
    useLayoutEffect(() => {

        if (!isLoggedIn()) {
            return navigate('/');
        }


    }, [])


    const loadVehicles = () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };


        fetch(process.env.WASHCAR_API_URL + "/business/my-vehicles", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setLoading(false);
                setVehicles(resultData.data.vehicles);
                setVehicleCategories(resultData.data.vehicle_categories);

            }) //
    }

    const deleteVehicle = async (vehicleID) => {


        setPopup({
            show: true,
            vehicleID,
        });



    };


    const handleDeleteTrue = () => {



        if (popup.show && popup.vehicleID) {


            try {

                let filteredArray = vehicles.filter(item => item.id !== popup.vehicleID)
                setVehicles(filteredArray);

                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                };

                fetch(process.env.WASHCAR_API_URL + "/customer-vehicle/delete/" + popup.vehicleID, requestOptions)
                    .then(response => response.json()) // parse JSON from request
                    .then(resultData => {
                        toast.success(resultData.alert.text);

                    })


            } catch (err) {
                console.log(err);
            }



            setPopup({
                show: false,
                id: null,
            });
        }
    };

// This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    const handleDeleteFalse = () => {

        setPopup({
            show: false,
            id: null,
        });
    };




    const handleSubmit = async (e) => {

        e.preventDefault();
        setSubmitDisabled(true);
        try {


            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    make_and_model: makeAndModel,
                    reg_number: regNumber,
                    category_id: categoryId

                })
            };


            fetch(process.env.WASHCAR_API_URL + "/customer-vehicle/store", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {


                    if(resultData.status === 'Error'){
                        toast.error(resultData.alert.text);
                    }
                    else{
                        //@todo can with out fetch????
                        setMakeAndModel("");
                        setRegNumber("");

                        toast.success(resultData.alert.text);
                        loadVehicles();
                    }

                    setSubmitDisabled(false);


                }) // set data for the number of stars

        } catch (err) {
            console.log(err);
        }

    };


    return (
        <Layout>

            <Seo title={t('vehicles')}/>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {popup.show ?  <Popup
                handleDeleteTrue={handleDeleteTrue}
                handleDeleteFalse={handleDeleteFalse}
            /> : null}

            <h1 className="title">{t('vehicles')}</h1>

            {loading ? <div className="pos-center"><LoadingSpinner /></div> : <div>


                {vehicles.length === 0 ? <p className={'box notification is-warning'}>{t('no_vehicles_found_text')}</p> :
                    <VehiclesList vehicleCategories={vehicleCategories} vehicles={vehicles} deleteVehiclesFunction={deleteVehicle} />}

                <AddVehicle submitDisabled={submitDisabled} setMakeAndModel={setMakeAndModel} setCategoryId={setCategoryId} setRegNumber={setRegNumber} makeAndModel={makeAndModel} regNumber={regNumber} handleSubmitFunction={handleSubmit} vehicleCategories={vehicleCategories} categoryId={categoryId} />
            </div>}


        </Layout>
    )
}

export default Vehicles

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;