import React from 'react';
import {getVehicleImage} from "../../utils/helper.service";
import {useTranslation} from "gatsby-plugin-react-i18next";


const VehiclesList = (props) => {

    const {t} = useTranslation();

    return (

        <div className="card">

            <header className="card-header">
                <p className="card-header-title">
                    {t('added_vehicles')}
                </p>

            </header>

            <div className="card-content p-0">

                <div className="content notification">


                    <div className="columns is-multiline">

                        {props.vehicles.map(vehicle => (
                            <div key={vehicle.id} className="column is-4 has-text-centered">

                                <div className={'box'}>
                                    <p>{vehicle.make_and_model}</p>
                                   <img             style={{'maxWidth': `100px`}} src={`https://s3.eu-north-1.amazonaws.com/assets.washcar/img/svg/${getVehicleImage(props.vehicleCategories, vehicle.category_id)}`}  />
                                    <p>{vehicle.reg_number}</p>
                                    <p><button value={vehicle.id} onClick={(e) => props.deleteVehiclesFunction(e.target.value)}  className={'button is-danger'}>{t('delete')}</button></p>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </div>
        </div>



    );
};

export default VehiclesList;
