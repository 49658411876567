const helperService = {}

export const convertPrice = amount => {
  let eur = amount / 100
  return eur.toLocaleString("en-US", { style: "currency", currency: "EUR" })
}

export const addSumForVehicleCategoryAndConvertPrice = (
  service,
  selectedCategory
) => {
  let priceWithCategoryBonusPrice = service.prices[selectedCategory.id]
  let eur = priceWithCategoryBonusPrice / 100
  return eur.toLocaleString("en-US", { style: "currency", currency: "EUR" })
}

export const removeEven = (arr, invert = false) => {
  for (let i = 0; i < arr.length; ++i) {
    const even = arr[i] % 2 === 0
    if (even ^ invert) {
      arr.splice(i--, 1)
    }
  }
  return arr
}

export const formatTime = my_date => {
  let d = new Date(my_date)

  return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear()
}

export const secondsToMinutes = seconds => {
  return Math.floor(seconds / 60)
}

export const isAdditionalHidden = key => {
  return parseInt(key) !== 1 ? "is-hidden" : ""
}

export const getDiscountValue = (price_in_cents, discountPercent) => {
  let numVal2 = Number(discountPercent) / 100
  let totalValue = price_in_cents - price_in_cents * numVal2

  let eur = totalValue / 100
  return eur.toLocaleString("en-US", { style: "currency", currency: "EUR" })
}

export const getDiscountValueWithNotAppleySum = (price_in_cents, discountPercent,totalPriceDiscountsNotappleyFromServer) => {

  let totalValue;
  let realPriceInCentsFRomCalculateDiscount = parseInt(price_in_cents) - parseInt(totalPriceDiscountsNotappleyFromServer);
 // console.log('xxx' + totalPriceDiscountsNotappleyFromServer);
// console.log(totalPriceDiscountsNotappleyFromServer);

  //ned calculate discount
  if(parseInt(realPriceInCentsFRomCalculateDiscount) > 0){

    //console.log('aaaa');
    let numVal2 = Number(discountPercent) / 100
    totalValue = price_in_cents - realPriceInCentsFRomCalculateDiscount * numVal2

 
  }
  else{
    totalValue = price_in_cents;
   // console.log('bbbbbbbbbbbbbb');
   
  }

  let eur = totalValue / 100
  return eur.toLocaleString("en-US", { style: "currency", currency: "EUR" })
}

export const getDiscountValueWithCategory = (
  service,
  discountPercent,
  selectedCategory
) => {
  let totalValue
  let price_in_cents = service.prices[selectedCategory.id]

  if (service.accept_discounts) {
    let numVal2 = Number(discountPercent) / 100
    totalValue = price_in_cents - price_in_cents * numVal2
  } else {
    totalValue = price_in_cents
  }

  let eur = totalValue / 100
  return eur.toLocaleString("en-US", { style: "currency", currency: "EUR" })
}

export const getVehicleImage = (vehicleCategories, vehicleCategoryId) => {
  let recordKey = vehicleCategories.findIndex(
    el => parseInt(el.id) === parseInt(vehicleCategoryId)
  )
  let selectedCategory = vehicleCategories[recordKey]

  if (typeof selectedCategory !== "undefined") {
    return selectedCategory.image
  } else {
    //@todo check!!!
    return "xxx"
  }
}
export default helperService
